@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'styles/components';
@import 'styles/custom';
@import 'styles/mui';

main {
  min-height: 100vh;
}

:root {
  --color-primary-light: #8e7dff;
  --color-primary-main: #725dff;
  --color-primary-dark: #4f41b2;

  --color-secondary-light: #33ab9f;
  --color-secondary-main: #009688;
  --color-secondary-dark: #00695f;
}

body[data-theme='dark'] {
}

* {
  outline: none;
  border-color: rgba(0, 0, 0, 0.1);
}
